import { STEPS } from '../../../constants/values';

export interface LectureFlashParams {
  words: Array<string>;
  nbWords: number;
  wordsToDisplay: Array<string>;
  displayDuration: number;
  symboleAttente: string;
  step: string;
  index: number;
}

export const LECTURE_FLASH_PARAMS_DEFAULT_VALUES: LectureFlashParams = {
  words: ['Chien', 'Guitare', 'Fraise'],
  nbWords: 2,
  wordsToDisplay: ['Chien', 'Fraise'],
  displayDuration: 1,
  symboleAttente: '🐶',
  step: STEPS.PRESENTATION,
  index: -1,
};
