import React from 'react';
import { Link } from 'react-router-dom';
import './homepage.scss';
import { ReactComponent as CluxLogo } from '../../assets/clux.svg';
import { ROUTES } from '../../constants/routes';

const Homepage = () => {
  return (
    <div className="homepage-container">
      <CluxLogo className="homepage-logo" />
      <div className="homepage-infos">
        <Link to={ROUTES.ATELIERS.path}>🚧 Site en construction 🚧</Link>
      </div>
    </div>
  );
};

export default Homepage;
