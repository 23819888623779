import { ThemeProvider } from '@mui/material';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import { ROUTES } from './constants/routes';
import { theme } from './style/theme';
import {
  ChoixAtelier,
  Homepage,
  LectureFlash,
  Oups,
  StyleGuide,
} from './scenes';

// TODO _ charger les composants en async

const App = () => {
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path={ROUTES.ATELIERS.path} element={<ChoixAtelier />} />
            <Route
              path={ROUTES.ATELIER_LECTURE_FLASH.path}
              element={<LectureFlash />}
            />
            <Route path={ROUTES.HOME.path} element={<Homepage />} />
            <Route path={ROUTES.STYLEGUIDE.path} element={<StyleGuide />} />
            <Route path="*" element={<Oups />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default App;
