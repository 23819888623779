import React from 'react';
import { Link } from 'react-router-dom';
import './choixAtelier.scss';
import { ROUTES_ATELIERS } from '../../constants/routes';

const ChoixAtelier = () => {
  return (
    <div className="choix-atelier-container">
      {Object.values(ROUTES_ATELIERS).map((atelier) => (
        <Link to={atelier.path}>
          {/* TODO _ nouveau compo card avec couleurs clux */}
          <div className="choix-atelier-item">
            <h3>{atelier.label}</h3>
            <p>{atelier.description}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ChoixAtelier;
