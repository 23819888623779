import React from 'react';
import './oups.scss';

const Oups = () => {
  return (
    <div className="oups-container">
      <h3>Oups!</h3>
      <p>Cette page n'existe pas ...</p>
    </div>
  );
};

export default Oups;
