import { ColorBar } from '..';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SettingsDialog = ({ open, children, ...props }) => (
  <Dialog fullScreen open={open} TransitionComponent={Transition} {...props}>
    <ColorBar withGradient />
    {children}
  </Dialog>
);

export default SettingsDialog;
