import { Box } from '@mui/material';
import React, { useState } from 'react';
import { PrimaryButton, SettingsDialog } from '../../../components';

const Modales = () => {
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  return (
    <>
      <Box display="flex" gap={2} flexWrap={'wrap'}>
        <PrimaryButton onClick={() => setSettingsDialogOpen(true)}>
          Ouvrir une modale plein ecran
        </PrimaryButton>
      </Box>
      <SettingsDialog open={settingsDialogOpen}>
        <Box p={2}>
          <PrimaryButton onClick={() => setSettingsDialogOpen(false)}>
            Fermer
          </PrimaryButton>
        </Box>
      </SettingsDialog>
    </>
  );
};

export default Modales;
