import { Box, Slider, TextField } from '@mui/material';
import { Formik } from 'formik';
import { get, join, set } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { ACCENTS, PONCTUATIONS } from '../../../../constants/strings';
import { STEPS } from '../../../../constants/values';
import {
  LECTURE_FLASH_PARAMS_DEFAULT_VALUES,
  LectureFlashParams,
} from '../params';
import {
  PrimaryButton,
  SecondaryButton,
  SettingsDialog,
} from '../../../../components';

const Settings = ({
  open,
  params,
  setParams,
}: {
  open: boolean;
  params: LectureFlashParams;
  setParams: Function;
}) => {
  const handleStart = (values) => {
    setParams({
      ...params,
      ...getNewParamsValues(values),
      step: STEPS.ATELIER,
      index: LECTURE_FLASH_PARAMS_DEFAULT_VALUES.index,
    });
  };

  // TODO _ maj params a chaque onChange & onBlur ?
  // const handleUpdateParams = (values) => {
  //   setParams({
  //     ...params,
  //     ...getNewParamsValues(values),
  //   });
  // };

  // TODO _ si besoin : generateRandomList(words, nbWords)
  const getNewParamsValues = (values) => ({
    words: values.words.split(';'),
    wordsToDisplay: values.words.split(';'),
    displayDuration: values.displayDuration,
  });

  // TODO _ créer un compo segment avec header violet pour le titre, exemple : Reglages
  return (
    <SettingsDialog open={open}>
      <Box p={2}>
        {params.step === STEPS.PRESENTATION && (
          <>
            <h2>Un atelier pour lire des mots !</h2>
            <PrimaryButton
              onClick={() => setParams({ ...params, step: STEPS.PARAMETRES })}
            >
              Demarrer
            </PrimaryButton>
          </>
        )}
        {params.step === STEPS.PARAMETRES && (
          <>
            <h2>Reglages</h2>
            {/* TODO _ selectionner ici listes */}
            <Formik
              initialValues={{
                words: join(params.words, ';'),
                displayDuration: params.displayDuration,
              }}
              validate={(values) => {
                console.log('values', values);

                const errors = {};

                const REGEX_MOT_OU_NOMBRE = `([A-Za-z${ACCENTS}${PONCTUATIONS}]+|[0-9]+)`;
                const REGEX_MOTS = new RegExp(
                  `^(${REGEX_MOT_OU_NOMBRE}(;${REGEX_MOT_OU_NOMBRE})*)$`
                );
                if (!get(values, 'words')) {
                  set(errors, 'words', 'Il faut ajouter des mots !'); // TODO _ mettre tous les texts dans un fichier
                } else if (!REGEX_MOTS.test(get(values, 'words'))) {
                  set(
                    errors,
                    'words',
                    'Il faut séparer les mots par un point-virgule'
                  );
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  console.log(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                  handleStart(values);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    name="words"
                    label="Mots"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={get(values, 'words')}
                    color="primary" // TODO _ surcharger la couleur primary
                    sx={{
                      m: '1rem 0',
                    }}
                    error={get(touched, 'words') && !!get(errors, 'words')}
                    helperText={get(errors, 'words')}
                  />
                  <Slider
                    aria-label="Durée d'affichage"
                    name="displayDuration"
                    value={get(values, 'displayDuration')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    getAriaValueText={(value) => `${value}s`}
                    valueLabelDisplay="auto"
                    shiftStep={1}
                    step={0.5}
                    min={0.5}
                    max={20}
                  />
                  <p>{`Temps d'affichage du mot : ${get(
                    values,
                    'displayDuration'
                  )} seconde(s)`}</p>
                  <PrimaryButton onClick={handleSubmit} disabled={isSubmitting}>
                    Commencer
                  </PrimaryButton>
                </form>
              )}
            </Formik>
          </>
        )}
        {params.step === STEPS.RESULTATS && (
          <>
            <h2>Resultats</h2>
            <PrimaryButton
              onClick={() => setParams({ ...params, step: STEPS.PARAMETRES })}
              mr={2}
            >
              Recommencer
            </PrimaryButton>
            <Link to={ROUTES.ATELIERS.path}>
              <SecondaryButton sx={{ ml: '0.5rem' }}>Quitter</SecondaryButton>
            </Link>
          </>
        )}
      </Box>
    </SettingsDialog>
  );
};

export default Settings;
