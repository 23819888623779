import { Box } from '@mui/material';
import React from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components';

const Boutons = () => {
  return (
    <Box display="flex" gap={2} flexWrap={'wrap'}>
      <PrimaryButton>Bouton primaire</PrimaryButton>
      <SecondaryButton>Bouton secondaire</SecondaryButton>
    </Box>
  );
};

export default Boutons;
