export const ROUTES_APP = {
  HOME: { path: '/' },
  ATELIERS: { path: '/ateliers' },
  STYLEGUIDE: { path: '/styleguide' },
};

export const ROUTES_ATELIERS = {
  ATELIER_LECTURE_FLASH: {
    path: `${ROUTES_APP.ATELIERS.path}/lecture-flash`,
    label: 'Lecture Flash',
    description: 'Un atelier qui affiche des mots',
  },
};

export const ROUTES = {
  ...ROUTES_APP,
  ...ROUTES_ATELIERS,
};
