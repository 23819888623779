export const STEPS = {
  PRESENTATION: 'PRESENTATION',
  PARAMETRES: 'PARAMETRES',
  ATELIER: 'ATELIER',
  RESULTATS: 'RESULTATS',
};

// TODO _ créer une enum Steps :
// enum Direction {
//   Up = 'UP',
//   Down = 'DOWN',
//   Left = 'LEFT',
//   Right = 'RIGHT',
// }
